<template>
<div class="station-alarm-container">
  <div class="config-tree-area">
    <GroupTree />
  </div>
  <div class="device-data-area">
    <div class="alarm-data-query">
      <span>{{showLang('com.tab.title')}}：</span>
      <Input type="text" v-model="filter.name" clearable :placeholder="showLang('com.tab.title')" style="width: 140px; margin-right: 5px;"></Input>
      <span>通信ID：</span>
      <Input type="text" v-model="filter.code" clearable placeholder="通信ID" style="width: 140px; margin-right: 5px;"></Input>
      <Button type="primary" size="default" style="margin-right: 5px; width: 100px;" :loading="loading" @click="search">查询</Button>
      <template v-for="(cmd, idx) in menus">
        <Button v-if="idx < cmdcnt" :key="idx" type="info" size="default" style="margin-right: 5px" @click="clickCmd(cmd)">{{cmd.name}}</Button>
      </template>
      <Dropdown style="margin-right: 5px" v-if="menus.length > cmdcnt">
        <Button type="info">
          {{showLang('com.op.other')}}<Icon type="ios-arrow-down"></Icon>
        </Button>
        <div slot="list" class="btns-flex" style="width: 500px">
          <template v-for="(cmd, idx) in menus">
            <Button v-if="idx >= cmdcnt" :key="idx" type="info" size="default" style="margin: 5px" @click="clickCmd(cmd)">{{cmd.name}}</Button>
          </template>
        </div>
      </Dropdown>
      <Button v-if="menus.length > 0" type="success" size="default" style="margin-right: 5px" @click="sendFailed">{{showLang('com.but.failed.resend')}}</Button>
      <!-- <Button v-if="menus.length > 0" type="success" size="default" style="margin-right: 5px" @click="exportResult">{{showLang('com.op.export.results')}}</Button> -->
      <!-- <Checkbox v-model="clearOldLog">{{showLang('com.lamp.is.ins')}}</Checkbox> -->
      <!-- <Checkbox v-model="isScreenSelected">{{showLang('com.lamp.is.screen')}}</Checkbox> -->
    </div>
    <div class="alarm-data-area" ref="table">
      <vxe-table border resizable show-overflow show-header-overflow keep-source ref="chkTable" :key="dataRefresh" stripe
        @checkbox-change="selectChangeEvent" :data="devices" height="auto" :row-config="{isHover: true}" :loading="loading"
        :checkbox-config="{checkField: 'checked',}">
        <vxe-column type="checkbox" width="60" fixed="left"></vxe-column>
        <vxe-column field="name" :title="showLang('com.tab.title')" fixed="left" width="250" sortable header-align="center"></vxe-column>
        <vxe-column field="code" :title="showLang('com.export.cn.id')" fixed="left" width="150" sortable header-align="center"></vxe-column>
        <vxe-column field="groupName" title="所在分区" width="200" sortable header-align="center"></vxe-column>
        <vxe-column field="deviceTypeName" title="设备类型" width="200" sortable header-align="center"></vxe-column>
        <vxe-column field="count" title="灯控数量" width="100" sortable header-align="center"></vxe-column>
        <vxe-column field="_message" :title="showLang('com.ins.result')" header-align="center" sortable width="500"></vxe-column>
        <vxe-column field="online" title="最后通信时间" width="200" sortable header-align="center">
          <template #default="params">
            {{ params.row.online ? params.row.lastTransTime : '离线' }}
          </template>
        </vxe-column>
        <vxe-column field="iccid" title="ICCID" width="200" sortable header-align="center"></vxe-column>
        <vxe-column field="imei" title="IMEI" width="200" sortable header-align="center"></vxe-column>
        <vxe-column field="hardware" title="硬件版本" width="200" sortable header-align="center"></vxe-column>
        <vxe-column field="software" title="软件版本" width="200" sortable header-align="center"></vxe-column>
      </vxe-table>
    </div>
    <vxe-pager :layouts="pageLayouts" :current-page.sync="filter.index" :page-size.sync="filter.size" :total="total" :page-sizes='pageSizes' @page-change="handlePageChange"></vxe-pager>
    <ModalArgEdit v-model="showArgModal" :command="cmdItem" @saved="argsSaved" />
    <ModalWaiting v-model="showWaitingModal" :item="cmdItem" />
    <ModalCheckAuth v-model="showCheckAuthModal" :users="checkAuthModalUsers" @checked="cmdAuthUserChecked" />
  </div>
</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalArgEdit from './ModalArgEdit'
import ModalWaiting from './ModalWaiting'
import exportXlsx from '@/components/xlsx/exportXlsx'
import ModalCheckAuth from './ModalCheckAuth'
import GroupTree from '../../../common/treeGroup/Index'
export default {
  name: 'CommandChannelIndex',
  components: {
    ModalArgEdit,
    ModalWaiting,
    ModalCheckAuth,
    GroupTree,
  },
  props: {
  },
  data() {
    return {
      loading: false,
      filter: {
        groupId: 0,
        name: '',
        code: '',
        index: 1,
        size: 20,
      },
      originalDevices: [],
      isScreenSelected: false,
      clearOldLog: this.clearOldLogs,
      cmdType: 'monitor',
      showCheckAuthModal: false,
      checkAuthModalSuccessFunction: null,
      checkAuthModalUsers: [],
      showWaitingModal: false,
      showArgModal: false,
      showPlanModal: false,
      showCreateModal: false,
      showStartOtaModal: false,
      cmdItem: { item: {}, other: {}, args: {} },
      cmdcnt: 10,
      devices: [],
      total: 0,
      dataRefresh: 0,
      arrCmds: [],
      cmdResults: {},
      timer: null,
      menus: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes','showLang']),
    ...mapState('auth', ['config']),
    ...mapState('cmd', ['cmdResultRefresh', 'commands', 'windowOnResize', 'clearOldLogs']),
    ...mapState('common', ['pageLayouts', 'pageSizes', 'coms6038names']),
    ...mapState('group', ['groups', 'groupTreeSelectedNode']),
  },
  watch: {
    clearOldLog() {
      this.$store.commit('cmd/setOldLogStatus', this.clearOldLog)
    },
    isScreenSelected() {
      this.screenSelected();
    },
    groupTreeSelectedNode() {
      this.initList();
    },

  },
  mounted: function () {
    this.getCommands();
    this.clearOldLog = this.clearOldLogs;
    this.timer = setInterval(this.setCmdResult, 500);
    window.eventBus.$on('paramCommandComing', params => {
      // this.cmdResults[params.cmdId] = params;
      params.time = new Date().getTime();
      this.cmdResults[params.commandId] = params;
    })
  },
  destroyed: function () {
    window.eventBus.$off('paramCommandComing');
    clearInterval(this.timer);
  },
  methods: {
    handlePageChange({ currentPage, pageSize }) {
      this.filter.index = currentPage
      this.filter.size = pageSize
      this.initList()
    },
    search: function(){
      this.filter.index = 1;
      this.initList();
    },
    initList: function () {
      if(this.loading)return;
      this.filter.groupId = this.groupTreeSelectedNode.id;
      this.loading = true;
      this.$axios.post(`device/light/QueryGatewayState`, this.filter).then(res => {
        this.loading = false;
        if (res.code == 0) {
          this.$set(this, 'total', res.data.count);
          this.$set(this, 'devices', res.data.list);
          this.calcSuccessRate();
        }
      });
    },
    getCommands: function(){
      this.$axios.post(`device/light/QueryGatewayCmds`, { }).then(res => {
        if (res.code == 0) {
          this.$set(this, 'menus', res.data);
        }
      });
    },
    screenSelected() {
      if (this.isScreenSelected) {
        this.devices = this.devices.filter(p => p.checked);
      } else {
        this.devices = this.originalDevices;
      }
    },
    setCmdResult: function(){
      for(let cmdId in this.cmdResults){
        let ds = this.devices.filter(p => p._cmdId == cmdId);
        if(ds.length == 0){
          if(new Date().getTime() - this.cmdResults[cmdId].time > 100000){
            console.log('cmd id time out');
            delete this.cmdResults[cmdId];
          }
          continue;
        }else{
          let item = this.cmdResults[cmdId];
          this.$set(ds[0], '_status', item.status);
          this.$set(ds[0], '_result', item.message);
          this.$set(ds[0], '_content', item.content);
          this.$set(ds[0], '_message', this.getCmdStatus(ds[0]));
          delete this.cmdResults[cmdId]
        }
      }
      this.calcSuccessRate();
    },
    calcSuccessRate: function(){
      let total = this.devices.length;
      let success = this.devices.filter(p => p.status == 9).length;
      let rate = 0;
      if(total <= 0){
        rate = 0;
      }else{
        rate = (success * 100 / total).toFixed(2);
      }
      window.eventBus.$emit('commandSuccessRateChanged', {total, success, rate });
    },
    selectChangeEvent({ rowIndex }) {
      this.$refs.chkTable.reloadRow(this.list, null, rowIndex)
    },
    exportResult: function () {
      let cmdData = [];
      this.devices.map(item => {
        cmdData.push({
          name: item.name,
          sname: item.sname,
          code: item.code,
          result: this.getCmdStatus(item)
        })
      });
      let tabHead = {
        name:this.showLang('com.ins.ch.name'),
        sname:this.showLang('com.site.name'),
        code:this.showLang('com.ins.last.id'),
        result:this.showLang('com.ins.result')
      }
      exportXlsx(cmdData, tabHead, this.showLang('com.ins.result'));
    },
    sendFailed: function () {
      let chks = this.$refs.chkTable.getCheckboxRecords();
      let cmds = [];
      let code = '';
      let args = {};
      for (let d of this.devices) {
        if ((d._status == 7 || d._status == 3 || d._status == 8) && chks.filter(p => p.id == d.id).length > 0) {
          this.$set(d, '_status', 0)
          this.$set(d, '_result', this.showLang('com.lighting.repeat.preparation'))
          this.$set(d, '_message', this.getCmdStatus(d))
          cmds.push(d.id);
          code = d._cmd;
          args = d._args;
        } else if (this.clearOldLog) {
          this.$set(d, '_status', 0)
          this.$set(d, '_result', '')
        }
      }
      let cmd = {
        code: code,
        list: cmds,
        checkUserId: 0,
        args: args
      };
      setTimeout(() => {
        this.sendCommand(cmd);
      }, 500)
    },
    cmdAuthUserChecked: function(params){
      // console.log('auth user check result', params, this.checkAuthModalSuccessFunction);
      if(this.checkAuthModalSuccessFunction){
        this.checkAuthModalSuccessFunction(params);
      }
    },
    checkCmdAuth: function(type, cmdCode, checkOkFun){
      this.$axios.post(`//${this.domains.trans}/sys/setting/CheckCmdAuth`, {type, code: cmdCode}).then(res => {
        if(res.code != 0)return;
        if(!res.data.need){
          checkOkFun({userId: 0});
          return;
        }
        this.checkAuthModalSuccessFunction = checkOkFun;
        this.checkAuthModalUsers = res.data.users;
        this.showCheckAuthModal = true;
      });
    },
    clickCmd: function (params) {
      // console.log('click cmd', params);
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning("请选择要控制的网关");
        return;
      }
      if (params.code == 'getCmdCount') {
        let ids = chks.map(p => p.id);
        this.getCmdCount(ids);
        return;
      } else if (params.code == 'clearCmds') {
        let ids = chks.map(p => p.id);
        this.clearCmds(ids);
        return;
      } else if (params.needPswd || params.needArgs) {
        this.cmdItem = { paramsCode: params.code, code: params.code, name: params.name, pswd: params.needPswd, args: {} }
        this.showArgModal = true;
        return
      } else {
        this.checkCmdAuth(this.cmdType, params.code, (checkResult) => {
          if (this.clearOldLog) {
            for (let item of this.devices) {
              item._result = '';
              item._status = 0;
            }
          }
          let cmds = []
          for (let chk of chks) {
            cmds.push(chk.id);
          }
          let cmd = {
            code: params.code,
            list: cmds,
            checkUserId: checkResult.userId,
            args: params.args
          };
          this.sendCommand(cmd);
        });
      }
    },
    argsSaved: function (params) {
      this.checkCmdAuth(this.cmdType, params.paramsCode, (checkResult) => {
        this.showArgModal = false;
        if (this.clearOldLog) {
          for (let item of this.devices) {
            item.result = '';
            item.status = 0;
          }
        }
        let chks = this.$refs.chkTable.getCheckboxRecords();
        let cmds = []
        let ds = {};
        let ns = [];
        for (let chk of chks) {
          ns.push(chk.pname);
          ds[chk.name] = chk.devId;
          // let args = this.clone(params.args);// {channel: chk.channel}
          // args.channel = chk.channel;
          // params.args.channel = chk.channel;
          // if (params.code == 'handControl') {
          //   args.point = false;
          //   // args.expire = params.args.expire;
          //   // params.args.point = false;
          // } else if (params.code == 'setYearPlan') {
          //   // params.args.timeId = chk.timeId;
          //   args.timeId = chk.timeId;
          // } else if(params.code == "handGroup11" || params.code == "handGroup10"){
          //   params.code = "handGroup1";
          // } else if(params.code == "handAll1" || params.code == "handAll0"){
          //   params.code = "handAll";
          // }
          // chk.result = this.showLang('com.lighting.ready.sending');
          // chk.status = 0;
          // chk.cmd = params.code;
          // chk.name = params.name;
          // chk.args = args;
          // chk.message = this.getCmdStatus(chk);
          // cmds.push({ id: chk.id, code: params.code, name: params.name, deviceId: chk.devId, checkUserId: checkResult.userId, args: args });
          cmds.push(chk.id);
        }
        let cmd = {
          code: params.code,
          list: cmds,
          checkUserId: checkResult.userId,
          args: params.args
        };
        if (params.code == 'update') {
          this.$Modal.confirm({
            title: this.showLang('com.op.prompt'),
            content: this.showLang('com.ins.upgrade.device',ns),
            onOk: async () => {
              this.sendCommand(cmd);
            },
          });
        } else {
          if(params.code == 'startReadWhiteFlag'){
            this.cmdItem = {id: chks[0].id}
            this.showWaitingModal = true;
          }
          this.sendCommand(cmd);
        }
      });
    },
    getCmdCount: function (ids) {
      this.$axios.post(`device/light/QueryGatewayCount`, { ids }).then(res => {
        if (res.code == 0) {
          for(let devId in res.data){
            let ds = this.devices.filter(p => p.id == devId);
            if(ds.length == 0)continue;
            this.$set(ds[0], '_cmd', 'getCmdCount');
            this.$set(ds[0], '_status', 9);
            this.$set(ds[0], '_result', res.data[devId]);
            this.$set(ds[0], '_message', `${this.showLang('com.ins.get.result')}：${res.data[devId]}`);
          }
        }
      })
    },
    clearCmds: async function (ids) {
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.ins.clean.ins.dev'),
        onOk: async () => {
          this.$axios.post(`device/light/ClearGatewayQueue`, { ids }).then(res => {
            if (res.code == 0) {
              for(let devId in res.data){
                let ds = this.devices.filter(p => p.id == devId);
                if(ds.length == 0)continue;
                this.$set(ds[0], '_cmd', 'clearCmds');
                this.$set(ds[0], '_status', 9);
                this.$set(ds[0], '_result', res.data[devId]);
                this.$set(ds[0], '_message', `${res.data[devId]}`);
              }
            }
          })
        },
      });
    },
    getCmdStatus: function (cmd) {
      // console.log('cmd status changed', cmd)
      switch (cmd._status) {
        case 0: return `${cmd._result}`;
        case 2: return this.showLang('cmd.has.sent')+this.showLang('com.ins.dev.reply');
        case 3: return cmd._result;
        case 4: return this.showLang('com.ins.execution')+`：${cmd._content.pkg} / ${cmd._content.total}`;
        case 5: return this.showLang('com.ins.fail.retry')+`：${cmd._result}`;
        case 7: return this.showLang('cmd.has.timeout');
        case 8: return this.showLang('com.ins.fail')+`：${cmd._result}`;
        case 9: {
          // console.log('getCmdStatus', cmd)
          switch (cmd._cmd) {
            case 'getK5Network': return `网络类型：${cmd._content.type == 0 ? '动态' : '静态'}，IP：${cmd._content.ip}`;
            case 'clearCmds': return `${this.showLang('com.but.set.ins.clean')}：${cmd._result}`;
            case 'getCmdCount': return `${this.showLang('com.ins.queues.num')}：${cmd._result}`;
            case 'getclock': return `${this.showLang('com.ins.success')}：${cmd._content.nowTime}`;
            case 'getLightUpdate': return `${this.showLang('com.ins.success')}：${cmd._content.flag == 1 ? this.showLang('com.state.started') : this.showLang('com.state.no.started')}`;
            case 'setLightUpdate': return `${this.showLang('com.ins.success')}：${cmd._content.flag == 1 ? this.showLang('com.state.ok.started') : this.showLang('com.tab.no.file')}`;
            case 'getSaveOtaFile': return `${this.showLang('com.ins.success')}：${cmd._content.flag == 1 ? this.showLang('com.state.allow') : this.showLang('com.state.prohibit')}`;
            case 'getWhite': {
              let is6038 = false;
              if(cmd._content.list.length > 0){
                let first = cmd._content.list[0];
                if(first.id)is6038 = true;
              }
              if (!is6038) {
                return `${this.showLang('com.ins.success')}，共${cmd._content.list.length}个：${cmd._content.list.join(', ')}`;
              }
              let cc = [];
              for (let item of cmd._content.list) {
                cc.push(`${item.id}:${item.no}`);
              }
              return `${this.showLang('com.ins.success')}，共${cmd._content.list.length}个：${cc.join(', ')}`;
            }
            case 'getInfo': return `${this.showLang('com.site.hw')}：${cmd._content.hardware}，${this.showLang('com.site.sw')}：${cmd._content.software}，${this.showLang('com.user.manufacturer')}：${cmd._content.manufacture}`;
            case 'getSimInfo': return `ICCID：${cmd._content.iccid}，IMEI：${cmd._content.imei}，RSSI：${cmd._content.rssi}`;
            case 'getNetwork': return `IP:${cmd._content.host}, ${this.showLang('com.ins.port')}:${cmd._content.port}, ${this.showLang('com.ins.heartbeat')}:${cmd._content.heart}s`;
            case 'getLoraWork': return `${this.showLang('cmd.content.ch')}：${cmd._content.channel}，${this.showLang('cmd.content.rate')}：${cmd._content.speed}`;
            case 'getComArgs': return `${this.showLang('com.ins.success')}：${this.coms6038names[cmd._content.com]}${this.showLang('com.comm.method')}：${cmd._content.type}，${this.showLang('com.BAUD')}：${cmd._content.buad}，${this.showLang('com.data.bits')}：${cmd._content.data}，${this.showLang('com.check.bit')}：${cmd._content.parity}，${this.showLang('com.stop.bit')}：${cmd._content.stop}`;
            default: return `${this.showLang('com.ins.success')} ${cmd._result}`;
          }
        }
      }
    },
    sendCommand: function(cmd){
      if(this.loading)return;
      if(cmd.list.length == 0)return;
      this.$set(this, 'cmdResults', {});
      for (let d of this.devices) {
        if(cmd.list.includes(d.id)){
          this.$set(d, '_message', '')
        }
      }
      setTimeout(() => {
        this.loading = true;
        this.$axios.post(`device/light/SendGatewayCommand`, cmd).then(res => {
          this.loading = false;
          if (res.code == 0) {
            for (let item of res.data.list) {
              let ds = this.devices.filter(p => p.id == item.id);
              if (ds.length == 0) continue;
              this.$set(ds[0], '_cmd', cmd.code);
              this.$set(ds[0], '_args', cmd.args);
              this.$set(ds[0], '_cmdId', item.success ? item.message : '');
              this.$set(ds[0], '_status', item.success ? 1 : 8);
              this.$set(ds[0], '_content', {});
              this.$set(ds[0], '_result', '');
              this.$set(ds[0], '_message', item.success ? '指令已进入发送队列' : item.message);
            }
          }
        });
      }, 100);
    },
  }
}
</script>
<style scoped>
.station-alarm-container {
  height: 100%;
  display: flex;
  /* flex-direction: column;
  align-content: stretch; */
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.config-tree-area{
  /* border: solid 1px #dcdee2; */
  width: 300px;
  flex: none;
  border-radius: 6px;
}
.device-data-area {
  margin-left: 10px;
  border: solid 1px #dcdee2;
  width: 300px;
  flex: auto;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
    background: #fff;
}
.alarm-data-query {
  height: 40px;
  flex: none;
  background-color: white;
  margin: 3px 1px;
  display: flex;
  align-items: center;
  padding: 10px;
  white-space: nowrap;
}
.alarm-data-area {
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  border-radius: 6px;
  padding: 0 5px;
}
.tab-active {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
.btns-flex {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
}
</style>